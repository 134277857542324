import { t } from 'i18next';
import _ from 'lodash';
import {
    addEdge,
    applyEdgeChanges,
    applyNodeChanges,
    Connection,
    Edge,
    EdgeChange,
    Node,
    NodeChange,
    updateEdge,
    XYPosition,
} from 'reactflow';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import { ImmerStateCreator } from './telma-portal-store';

import { MINIMAL_NODES } from '@/data/minimal-nodes';
import { StickersType } from '@/data/sticker-module-types';
import {
    Bot,
    BotFormGeneralInfoValues,
    BotModuleData,
    ModuleSource,
    NodeType,
    TaskValue,
} from '@/domain/bot';
import { botSchema } from '@/schemas/bot-validation-schema';
import { getBotRaw, getBotVersion, patchBotVersion } from '@/services/api/bots';
import {
    postConversationTasks,
    postVoicebotCall,
} from '@/services/api/campaigns';
import { autoInsertIntoLinearGraph } from '@/utils/auto-insert-into-linear-graph';
import { createNewEdgesOnNodeDelete } from '@/utils/create-new-edges-on-delete';
import { createNodeReactFlow } from '@/utils/create-node';
import { createSimpleEdgeReactFlow } from '@/utils/create-simple-edge';
import {
    getLayoutedElements,
    nodeHeight,
    nodeWidth,
} from '@/utils/get-layouted-elements';
import {
    createNewNodeId,
    getInsertPosition,
    getNodeSuccessors,
    shiftSuccessors,
} from 'utils/on-node-add';

export interface EditableBotVersionSlice {
    fetchBotCurrentVersion: (botId: string, accontId?: string) => void;
    fetchBotVersion: (
        botId: string,
        versionId: string,
        accountId?: string,
    ) => void;
    currentBotVersion: string;
    isLoadingBotVersion: boolean;
    itemsUndo: Bot[];
    itemsRedo: Bot[];
    editingBot: Bot;
    // lastTimeChangedUndoStack: number;
    lastTimeUndoOrRedo: number;

    errorFetchBotVersion?: {
        isErrorFetchBotVersion: boolean;
        errorMessageFetchBot: unknown;
        errorMessageFetchBotKey?: string;
    };

    saveBotVersion: (accountId?: string) => Promise<string>;
    errorSaveBotVersion?: {
        isErrorSaveBotVersion: boolean;
        errorMessageSaveBot: unknown;
        errorMessageSaveBotKey?: string;
    };

    changesInBotVersion: boolean;
    resetChangesInBotVersion: () => void;

    setBotGeneralInfo: (botGeneralInfo: BotFormGeneralInfoValues) => void;
    setNode: (
        nodeIndex: number,
        params: Record<string, any>,
        source?: ModuleSource,
    ) => void;
    setStickers: (stickers: StickersType) => void;
    setSelectedHandle: (handle: string | undefined) => void;
    onValidationChange: (valid: boolean, nodeIndex: number) => void;

    onNodesChange: (changes: NodeChange[]) => void;
    onNodesRegeneration: (nodes: any, edges: any) => void;
    setEdges: (edges: Edge[]) => void;
    onEdgesChange: (changes: EdgeChange[]) => void;
    onConnect: (connection: Connection) => void;
    onLayout: (direction?: string) => void;
    onNodesRemove: (ids: string[]) => void;
    onNodeAdd: (nodeType: NodeType) => void;
    updateNodePositions: (shiftedPs: Record<string, XYPosition>) => void;
    addedNodePosition: number;
    onEdgesRemove: (ids: string[]) => void;
    onEdgesFromHandleRemove: (ids: string[]) => void;
    onEdgeUpdate: (oldEdge: Edge, newConnection: Connection) => void;
    onUndo: () => void;
    onRedo: () => void;
    onSelectionChange: () => void;

    currentConversationTaskId?: number;
    resetCurrentConversationTaskId: () => void;
    sendConversationTask: (
        callee: {
            phone: string;
            phonePrefix: string;
        },
        accountId?: string,
    ) => void;
    sendVoicebotCall: (
        callee: {
            phone: string;
            phonePrefix: string;
        },
        campaignId: string,
        botId: string,
    ) => void;

    addRowTaskValuesTable: () => void;
    deleteRowTaskValuesTable: (rowId: string) => void;
    resetTaskValuesTable: () => void;
    updateCellTaskValuesTable: (
        rowId: string,
        columnName: keyof TaskValue,
        updatedValue: string,
    ) => void;

    setPromptTexts: (value: Record<string, string[]>) => void;
    setIntentTexts: (value: Record<string, string[]>) => void;
    setEntityTexts: (value: Record<string, string[]>) => void;

    unselectAllNodes: () => void;
}

const botVersionsAreDifferent = (bot1: Bot, bot2: Bot) => {
    if (bot1.name != bot2.name) {
        // console.log('Different name');
        return true;
    }
    if (bot1.description != bot2.description) {
        // console.log('Different desc');
        return true;
    }
    if (bot1.language != bot2.language) {
        // console.log('Different lang');
        return true;
    }
    if (bot1.voiceConfig?.ttsVoice?.voice != bot2.voiceConfig?.ttsVoice.voice) {
        // console.log('Different voice');
        return true;
    }
    if (bot1.nodesReactFlow.length != bot2.nodesReactFlow.length) {
        // console.log('Different node count');
        return true;
    }
    for (let i = 0; i < bot1.nodesReactFlow.length; i += 1) {
        const node1 = bot1.nodesReactFlow[i];
        const node2 = bot2.nodesReactFlow[i];

        if (JSON.stringify(node1.data) != JSON.stringify(node2.data)) {
            // console.log('Different node data');
            return true;
        }

        if (JSON.stringify(node1.position) != JSON.stringify(node2.position)) {
            // console.log('Different node position');
            return true;
        }
    }
    if (bot1.taskValues?.length != bot2.taskValues?.length) {
        // console.log('Different task value count');
        return true;
    }
    for (let i = 0; i < bot1.taskValues?.length ?? 0; i += 1) {
        if (bot1.taskValues[i].value != bot2.taskValues[i].value) {
            // console.log('Different names');
            return true;
        }
        if (
            bot1.taskValues[i].variableName != bot2.taskValues[i].variableName
        ) {
            // console.log('Different variable name');
            return true;
        }
    }
    return false;
};

const pushUndoAction = (
    state: EditableBotVersionSlice,
    get: () => EditableBotVersionSlice,
) => {
    if (new Date().getTime() - get().lastTimeUndoOrRedo < 100) {
        // is part of undo/redo action, ignore
        return;
    }

    if (
        state.itemsUndo.length > 0 &&
        !botVersionsAreDifferent(
            state.itemsUndo[state.itemsUndo.length - 1],
            get().editingBot,
        )
    ) {
        return;
    }
    // if (new Date().getTime() - get().lastTimeChangedUndoStack < 100) {
    //     // skip save to undo chain when last save was recently
    //     return;
    // }

    state.itemsUndo.push(get().editingBot);
    state.itemsRedo = [];
    // state.lastTimeChangedUndoStack = new Date().getTime();
    console.log('UNDO STACK', JSON.parse(JSON.stringify(state.itemsUndo)));
};

const resetUndoStack = (
    state: EditableBotVersionSlice,
    get: () => EditableBotVersionSlice,
) => {
    state.itemsUndo = [get().editingBot];
    state.itemsRedo = [];
    // state.lastTimeChangedUndoStack = new Date().getTime();
    state.lastTimeUndoOrRedo = 0;
};

export const editableBotVersionSlice: ImmerStateCreator<
    EditableBotVersionSlice
> = (set, get) => ({
    //values

    itemsUndo: [],
    itemsRedo: [],
    editingBot: {
        name: '',
        description: '',
        botId: '',
        botVersionId: '',
        voiceConfig: undefined,
        smsConfig: undefined,
        language: '',
        channel: 'voice',
        taskValues: [{ variableName: '', value: '', id: uuid() }],
        nodesReactFlow: MINIMAL_NODES,
        selectedHandle: undefined,
        edgesReactFlow: [],
        stickers: {
            S1: {
                name: 'default',
                description: '',
                appearance: {
                    color: '#fceaea',
                },
            },
        },
        labels: [],
    },
    currentBotVersion: '',
    lastTimeChangedUndoStack: 0,
    lastTimeUndoOrRedo: 0,

    //fetching bot's current version
    fetchBotCurrentVersion: async (botId, accountId) => {
        try {
            const botDetail = await getBotRaw(botId, accountId);
            set((state) => {
                state.currentBotVersion = botDetail.current_bot_version_id;
            });
        } catch (e) {
            console.log(e);
        }
    },

    //fetching values
    fetchBotVersion: async (botId, versionId, accountId) => {
        set((state) => {
            state.isLoadingBotVersion = true;
        });

        try {
            const botVersion = await getBotVersion(botId, versionId, accountId);

            const nodes = botVersion.nodesReactFlow;
            const edges = botVersion.edgesReactFlow;

            if (
                nodes.every(
                    (node) => node.position.x == 0 && node.position.y == 0,
                )
            ) {
                // node positions are not loaded, perform auto layout

                const dagreGraph = getLayoutedElements(nodes, edges);

                nodes.forEach((node, index) => {
                    nodes[index].position = {
                        x: dagreGraph.node(node.id).x - nodeWidth / 2,
                        y: dagreGraph.node(node.id).y - nodeHeight / 2,
                    };
                });
            }
            set((state) => {
                const bot = state.editingBot;
                bot.name = botVersion.name;
                bot.description = botVersion.description;
                bot.botId = botVersion.botId;
                bot.botVersionId = botVersion.botVersionId;
                bot.voiceConfig = botVersion.voiceConfig;
                bot.smsConfig = botVersion.smsConfig;
                bot.language = botVersion.language;
                bot.channel = botVersion.channel;
                bot.edgesReactFlow = edges;
                bot.nodesReactFlow = nodes;
                bot.taskValues = botVersion.taskValues;
                bot.prompt_texts = botVersion.prompt_texts;
                bot.intent_texts = botVersion.intent_texts;
                bot.entity_texts = botVersion.entity_texts;
                bot.mchannelsBotId = botVersion.mchannelsBotId;
                bot.labels = botVersion.labels;
                bot.stickers = botVersion.stickers;
                state.addedNodePosition = state.addedNodePosition;
                state.isLoadingBotVersion = false;
                state.errorFetchBotVersion = undefined;
                resetUndoStack(state, get);
            });
        } catch (e) {
            set((state) => {
                state.isLoadingBotVersion = false;
                state.errorFetchBotVersion = {
                    isErrorFetchBotVersion: true,
                    errorMessageFetchBot: e,
                    errorMessageFetchBotKey: `get_bot_version_${botId}_${versionId}`,
                };
            });
        }
    },

    isLoadingBotVersion: false,

    errorFetchBotVersion: undefined,

    //saving values to mappie
    saveBotVersion: async (accountId) => {
        const bot = get().editingBot;
        const newBotVersion = {
            name: bot.name,
            description: bot.description,
            voiceConfig: bot.voiceConfig,
            smsConfig: bot.smsConfig,
            language: bot.language,
            channel: bot.channel,
            edgesReactFlow: bot.edgesReactFlow,
            selectedHandle: bot.selectedHandle,
            nodesReactFlow: bot.nodesReactFlow,
            stickers: bot.stickers,
            taskValues: bot.taskValues,
            prompt_texts: bot.prompt_texts,
            intent_texts: bot.intent_texts,
            entity_texts: bot.entity_texts,
            mchannelsBotId: bot.mchannelsBotId,
        };

        try {
            const bS = botSchema(t);
            await bS.validate(newBotVersion, {
                abortEarly: false,
            });

            await patchBotVersion(
                bot.botId,
                bot.botVersionId,
                newBotVersion,
                accountId,
            );

            set((state) => {
                state.errorSaveBotVersion = undefined;
                state.changesInBotVersion = false;

                resetUndoStack(state, get);
            });

            return Promise.resolve('sucess');
        } catch (e) {
            let errorMessage: string;
            if (e instanceof yup.ValidationError) {
                errorMessage = e?.inner
                    .map((e) => `${e.path} - ${e.message}`)
                    .reduce((a, v) => `${a} ${v}`);
            }

            set((state) => {
                state.errorSaveBotVersion = {
                    isErrorSaveBotVersion: true,
                    errorMessageSaveBot: errorMessage,
                    errorMessageSaveBotKey: `get_bot_version_${bot.botId}_${bot.botVersionId}`,
                };
            });

            return Promise.reject();
        }
    },

    errorSaveBotVersion: undefined,

    //checking changes, reseting to default
    changesInBotVersion: false,
    resetChangesInBotVersion: () => {
        set((state) => {
            state.changesInBotVersion = false;
            state.isLoadingBotVersion = false;
            state.errorFetchBotVersion = undefined;

            resetUndoStack(state, get);
        });
    },

    //seting values based on form values
    setBotGeneralInfo: (botGeneralInfo) => {
        set((state) => {
            pushUndoAction(state, get);
            const bot = state.editingBot;
            bot.name = botGeneralInfo.name;
            bot.description = botGeneralInfo.description;
            bot.channel = botGeneralInfo.channel;
            bot.language = botGeneralInfo.language;
            // bot.labels = botGeneralInfo.labels;
            bot.voiceConfig = botGeneralInfo.voiceConfig
                ? {
                      inactivityTimeout:
                          botGeneralInfo.voiceConfig.inactivityTimeout,
                      initialUserResponseTimeout:
                          botGeneralInfo.voiceConfig.initialUserResponseTimeout,
                      ttsVoice: { ...botGeneralInfo.voiceConfig.ttsVoice },
                      stt: {
                          provider: botGeneralInfo.voiceConfig?.stt?.provider,
                          endOfSpeechTimeout: botGeneralInfo.voiceConfig?.stt
                              ?.endOfSpeechTimeout
                              ? Number(
                                    botGeneralInfo.voiceConfig?.stt
                                        ?.endOfSpeechTimeout,
                                )
                              : undefined,
                      },
                      ttsProsody: { ...botGeneralInfo.voiceConfig.ttsProsody },
                  }
                : undefined;
            state.changesInBotVersion = true;
            bot.mchannelsBotId = botGeneralInfo.mchannelsBotId;
        });
    },

    onUndo: () => {
        set((state) => {
            if (get().itemsUndo.length == 0) {
                return;
            }

            let index = get().itemsUndo.length - 1;
            while (
                !botVersionsAreDifferent(
                    get().itemsUndo[index],
                    get().editingBot,
                ) &&
                index > 0
            ) {
                index -= 1;
            }

            const item = get().itemsUndo[index];
            state.itemsUndo.splice(
                index > 0 ? index : 1,
                state.itemsUndo.length,
            );
            state.itemsRedo.splice(0, 0, get().editingBot);
            state.editingBot = item;

            state.lastTimeUndoOrRedo = new Date().getTime();
        });
    },

    onRedo: () => {
        set((state) => {
            if (get().itemsRedo.length == 0) {
                return;
            }
            const item = get().itemsRedo[0];
            state.itemsRedo.splice(0, 1);
            state.itemsUndo.push(get().editingBot);
            state.editingBot = item;

            state.lastTimeUndoOrRedo = new Date().getTime();
        });
    },

    setNode: (nodeIndex, params, source) => {
        set((state) => {
            //@ts-ignore - data type problem
            state.editingBot.nodesReactFlow[nodeIndex].data.params = {
                ...params,
            };
            if (source) {
                //@ts-ignore - data type problem
                state.editingBot.nodesReactFlow[nodeIndex].data.source = {
                    ...source,
                };
            }
            state.changesInBotVersion = true;
        });
    },

    setStickers: (stickers) => {
        set((state) => {
            //@ts-ignore - data type problem
            (state.editingBot.stickers = stickers),
                (state.changesInBotVersion = true);
        });
    },

    setSelectedHandle: (newSelHandle) => {
        set((state) => {
            if (newSelHandle) {
                const nodeId = newSelHandle.slice(0, -2);
                console.log(nodeId);
                const updatedNodes = state.editingBot.nodesReactFlow.map(
                    (node) => ({
                        ...node,
                        selected: node.id === nodeId,
                    }),
                );

                return {
                    ...state,
                    editingBot: {
                        ...state.editingBot,
                        selectedHandle: newSelHandle,
                        nodesReactFlow: updatedNodes,
                    },
                    changesInBotVersion: true,
                };
            } else {
                state.editingBot.selectedHandle = newSelHandle;
                state.changesInBotVersion = true;
            }
        });
    },

    onValidationChange: (isValid, nodeIndex) => {
        set((state) => {
            state.editingBot.nodesReactFlow[nodeIndex].data.valid = isValid;
            state.editingBot.nodesReactFlow[nodeIndex].selected = true;
        });
    },

    //React flow

    onNodesChange: (changes) => {
        setTimeout(() => {
            set((state) => {
                state.editingBot.nodesReactFlow = applyNodeChanges(
                    changes,
                    get().editingBot.nodesReactFlow,
                );
            });
        }, 2);
    },

    onNodesRegeneration: (newNodes, newEdges) => {
        set((state) => {
            state.editingBot.nodesReactFlow = newNodes;
            state.editingBot.edgesReactFlow = newEdges;
            state.changesInBotVersion = true;
            pushUndoAction(state, get);
        });
        get().onLayout();
    },

    setEdges: (newEdges) => {
        set((state) => {
            pushUndoAction(state, get);
            state.editingBot.edgesReactFlow = newEdges;
            state.changesInBotVersion = true;
        });
    },

    onEdgesChange: (changes) => {
        set((state) => {
            state.editingBot.edgesReactFlow = applyEdgeChanges(
                changes,
                get().editingBot.edgesReactFlow,
            );
        });
    },

    setPromptTexts: (value) => {
        set((state) => {
            state.editingBot.prompt_texts = value;
        });
    },

    setIntentTexts: (value) => {
        set((state) => {
            state.editingBot.intent_texts = value;
        });
    },

    setEntityTexts: (value) => {
        set((state) => {
            state.editingBot.entity_texts = value;
        });
    },

    onConnect: (connection) => {
        set((state) => {
            if (
                connection.source &&
                connection.target &&
                connection.source != connection.target &&
                connection.sourceHandle
            ) {
                pushUndoAction(state, get);

                state.editingBot.edgesReactFlow = addEdge(
                    createSimpleEdgeReactFlow(
                        connection.source,
                        connection.target,
                        connection.sourceHandle,
                    ),
                    get().editingBot.edgesReactFlow,
                );
                state.changesInBotVersion = true;
            }
        });
    },

    onLayout: () => {
        const nodesReactFlow = get().editingBot.nodesReactFlow;

        const dagreGraph = getLayoutedElements(
            nodesReactFlow,
            get().editingBot.edgesReactFlow,
        );

        nodesReactFlow.forEach((node, index) =>
            set((state) => {
                state.editingBot.nodesReactFlow[index].position = {
                    x: dagreGraph.node(node.id).x - nodeWidth / 2,
                    y: dagreGraph.node(node.id).y - nodeHeight / 2,
                };
            }),
        );
    },

    onNodesRemove: (ids) => {
        const currentNodes = get().editingBot.nodesReactFlow;
        const currentEdges = get().editingBot.edgesReactFlow;

        let deleted: Node<BotModuleData>[] = [];

        ids.forEach((id) => {
            const deletedNode = currentNodes.find((node) => node.id === id);
            if (deletedNode) {
                deleted.push(deletedNode);
            }
            return;
        });

        const newEdges = deleted.reduce(
            (currentEdges, node) =>
                createNewEdgesOnNodeDelete(currentEdges, node, currentNodes),
            currentEdges,
        );

        const newNodes = get().editingBot.nodesReactFlow.filter(
            (node) => !ids.includes(node.id),
        );

        set((state) => {
            pushUndoAction(state, get);

            state.editingBot.edgesReactFlow = newEdges;
            state.editingBot.nodesReactFlow = newNodes;
            state.changesInBotVersion = true;
        });
    },

    onSelectionChange: () => {
        set((state) => {
            pushUndoAction(state, get);
        });
    },

    updateNodePositions: (shiftedPositions: Record<string, XYPosition>) => {
        set((state) => {
            state.editingBot.nodesReactFlow =
                state.editingBot.nodesReactFlow.map((node) => {
                    if (shiftedPositions[node.id]) {
                        return {
                            ...node,
                            position: shiftedPositions[node.id],
                        };
                    }
                    return node;
                });
        });
    },

    onNodeAdd: (nodeType) => {
        const NEW_NODE_OFFSET = 125;

        const nodesReactFlow = get().editingBot.nodesReactFlow;
        const selectedHandle = get().editingBot.selectedHandle;
        const nodeId = createNewNodeId(nodeType, nodesReactFlow);

        const { selectedNodePosition, isLast, selectedNode } =
            getInsertPosition(nodesReactFlow);

        const newNode = createNodeReactFlow(
            nodeId,
            nodeType,
            undefined,
            undefined,
            selectedNodePosition.x,
            selectedNodePosition.y + (isLast ? 0 : NEW_NODE_OFFSET),
        );
        newNode.selected = true;
        const newEdges = autoInsertIntoLinearGraph(
            nodesReactFlow,
            get().editingBot.edgesReactFlow,
            nodeId,
            nodeType as any,
            selectedHandle,
        );

        set((state) => {
            pushUndoAction(state, get);

            state.editingBot.nodesReactFlow.forEach(
                (node) => (node.selected = false),
            );
            state.editingBot.nodesReactFlow.splice(1, 0, newNode);
            state.addedNodePosition = newNode.position.y;

            if (newEdges) {
                state.editingBot.edgesReactFlow = newEdges;
            }
        });

        const shiftAmount = NEW_NODE_OFFSET + 50;
        const successors = getNodeSuccessors(
            nodeId,
            get().editingBot.edgesReactFlow,
        );

        if (newEdges) {
            shiftSuccessors(
                successors,
                shiftAmount,
                nodesReactFlow,
                get().updateNodePositions,
                newEdges,
                selectedNode.id,
            );
        }
        get().setSelectedHandle(undefined);
    },

    addedNodePosition: 0,

    onEdgesRemove: (ids) => {
        const newEdges = get().editingBot.edgesReactFlow.filter(
            (edge) => !ids.includes(edge.id),
        );
        set((state) => {
            pushUndoAction(state, get);

            state.editingBot.edgesReactFlow = newEdges;
            state.changesInBotVersion = true;
        });
    },

    onEdgesFromHandleRemove: (ids) => {
        const newEdges = get().editingBot.edgesReactFlow.filter((edge) =>
            edge?.sourceHandle ? !ids.includes(edge?.sourceHandle) : false,
        );
        set((state) => {
            pushUndoAction(state, get);

            state.editingBot.edgesReactFlow = newEdges;
            state.changesInBotVersion = true;
        });
    },

    onEdgeUpdate: (oldEdge, newConnection) => {
        set((state) => {
            state.editingBot.edgesReactFlow = updateEdge(
                oldEdge,
                newConnection,
                state.editingBot.edgesReactFlow,
            );
            state.changesInBotVersion = true;
        });
    },

    // test call
    currentConversationTaskId: undefined,

    resetCurrentConversationTaskId: () => {
        set((state) => {
            state.currentConversationTaskId = undefined;
        });
    },

    sendVoicebotCall: async (
        callee: {
            phone: string;
            phonePrefix: string;
        },
        campaignId: string,
        botId: string,
    ) => {
        try {
            const { conversation_task_ids } = await postVoicebotCall(
                campaignId,
                botId,
                callee,
                get().editingBot.taskValues,
            );

            set((satte) => {
                satte.currentConversationTaskId = conversation_task_ids[0];
            });
        } catch (e) {
            console.log('Error posting voicebot call', e);
        }
    },

    sendConversationTask: async (callee, accountId) => {
        const bot = get().editingBot;
        const currentBot = {
            botId: bot.botId,
            botVersionId: bot.botVersionId,
            name: bot.name,
            description: bot.description,
            voiceConfig: bot.voiceConfig,
            smsConfig: bot.smsConfig,
            language: bot.language,
            channel: bot.channel,
            nodesReactFlow: bot.nodesReactFlow,
            selectedHandle: bot.selectedHandle,
            edgesReactFlow: bot.edgesReactFlow,
            taskValues: bot.taskValues,
            labels: bot.labels,
            stickers: bot.stickers,
            mchannelsBotId: bot.mchannelsBotId,
        };

        try {
            const { conversation_task_ids } = await postConversationTasks(
                bot.botId,
                {
                    currentBot: currentBot,
                    callee: callee,
                },
                accountId,
            );

            set((satte) => {
                satte.currentConversationTaskId = conversation_task_ids[0];
            });
        } catch (e) {
            // set({
            //     errorSaveBotVersion: {
            //         isErrorSaveBotVersion: true,
            //         errorMessageSaveBot: e,
            //         errorMessageSaveBotKey: `get_bot_version_${get().botId}_${
            //             get().botVersionId
            //         }`,
            //     },
            // });
        }
    },

    //handling variable table
    addRowTaskValuesTable: () => {
        set((state) => {
            state.editingBot.taskValues.push({
                variableName: '',
                value: '',
                id: uuid(),
            });
            state.changesInBotVersion = true;
        });
    },

    deleteRowTaskValuesTable: (rowId) => {
        set((state) => {
            pushUndoAction(state, get);

            state.editingBot.taskValues = state.editingBot.taskValues.filter(
                (row) => row.id !== rowId,
            );
            state.changesInBotVersion = true;
        });
    },

    resetTaskValuesTable: () => {
        set((state) => {
            state.editingBot.taskValues = [
                { variableName: '', value: '', id: uuid() },
            ];
            state.changesInBotVersion = true;
        });
    },

    updateCellTaskValuesTable: (rowId, columnName, updatedValue) => {
        set((state) => {
            pushUndoAction(state, get);

            const t = state.editingBot.taskValues.find(
                (row) => row.id === rowId,
            );
            if (t) {
                t[columnName] = updatedValue;
            }
            state.changesInBotVersion = true;
        });
    },

    unselectAllNodes: () => {
        set((state) => {
            state.editingBot.nodesReactFlow.forEach((node) => {
                node.selected = false;
            });
        });
    },
});
