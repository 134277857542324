import { TFunction } from 'i18next';
import * as yup from 'yup';

import { botCustomInternalModuleNodeSchema } from '@/components/editable-nodes/custom-internal-module-node-form';
import { botNumericRatingNodeSchema } from '@/components/editable-nodes/numeric-rating-node-form';
import { botOpenQuestionNodeSchema } from '@/components/editable-nodes/open-question-node-form';
import { promptNodeSchema } from '@/components/editable-nodes/prompt-node-form';
import { botSMSModuleNodeSchema } from '@/components/editable-nodes/sms-module-node-form';
import { botValueRangeRouterNodeSchema } from '@/components/editable-nodes/value-range-router-node-form';
import { botYesNoConfirmationNodeSchema } from '@/components/editable-nodes/yes-no-confirmation-node-form';

yup.setLocale({
    number: {
        max: ({ max }) => ({ key: 'must_be_less_than', values: { max } }),
        min: ({ min }) => ({ key: 'must_be_at_least', values: { min } }),
    },
});

// set to combine given value and translated string (as an error message)

export const botNodeSchema = (t: TFunction) => {
    return yup.object().shape({
        data: yup.object().shape({
            type: yup
                .string()
                .matches(
                    /(Prompt|SMSModule|OpenQuestion|YesNoConfirmation|NumericRatingModule|ValueRangeRouter|StickerModule|CalendarModule|CustomInternalModule|Start|Exit)/,
                )
                .required(),
            params: yup
                .object()
                .when('type', {
                    is: 'Prompt',
                    then: () => promptNodeSchema,
                })
                // params: yup.object().shape({ prompt: yup.string().max(5) }),
                .when('type', {
                    is: 'SMSModule',
                    then: () => botSMSModuleNodeSchema,
                })
                .when('type', {
                    is: 'OpenQuestion',
                    then: () => botOpenQuestionNodeSchema,
                })
                .when('type', {
                    is: 'YesNoConfirmation',
                    then: () => botYesNoConfirmationNodeSchema,
                })
                .when('type', {
                    is: 'NumericRatingModule',
                    then: () => botNumericRatingNodeSchema,
                })
                .when('type', {
                    is: 'ValueRangeRouter',
                    then: () => botValueRangeRouterNodeSchema(t),
                })
                // .when('type', {
                //     is: 'CalendarModule',
                //     then: () => {},
                // })
                .when('type', {
                    is: 'CustomInternalModule',
                    then: () => botCustomInternalModuleNodeSchema,
                }),
            // .when('type', {
            //     is: 'Start',
            //     then: () => {},
            // })
            // .when('type', {
            //     is: 'Exit',
            //     then: () => {},
            // }),
        }),
    });
};

export const botNodesSchema = (t: TFunction) => {
    return yup.array().of(botNodeSchema(t)).max(1000);
};

export const botSchema = (t: TFunction) => {
    return yup.object().shape({
        name: yup
            .string()
            .trim()
            .max(200, 'too_long')
            .required('name_required'),
        description: yup.string().max(200, 'too_long'),
        voiceConfig: yup.object(),
        smsConfig: yup.object(),
        language: yup.string(),
        channel: yup.string().matches(/(sms|voice)/),
        nodes: botNodesSchema(t),
        edges: yup.array().max(1000),
        taskValues: yup.array().max(1000),
    });
};
