import {
    AssessmentOutlined,
    ForumOutlined,
    LocalPhone,
} from '@mui/icons-material';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export interface Item {
    id: string;
    title: string;
    type: string;
    url: string;
    icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
        muiName: string;
    };
    external?: boolean;
    target?: boolean;
    disabled?: boolean;
    breadcrumbs?: boolean;
}

export const MENU_OPTIONS: Item[] = [
    {
        id: 'campaigns',
        title: 'campaigns',
        type: 'item',
        url: '/campaigns',
        icon: LocalPhone,
        breadcrumbs: false,
    },
    {
        id: 'bots',
        title: 'my_bots',
        type: 'item',
        url: '/bots',
        icon: ForumOutlined,
        external: true,
        target: true,
    },
    {
        id: 'analytics',
        title: 'analytics',
        type: 'item',
        url: '/analytics',
        icon: AssessmentOutlined,
        external: true,
        target: true,
    },
    {
        id: 'call',
        title: 'voicebot_call',
        type: 'item',
        url: '/voicebot',
        icon: ForumOutlined,
        external: true,
        target: true,
    },
];
